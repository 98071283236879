<template>
  <div class="row mt-1 project-unit-summary">
    <div class="col-4 mb-2 pr-2">
      <img
        class="main-photo"
        :src="$getFileURL(sale.project.mainPhoto[0])"
        :alt="sale.project.name"
        @error="setAltPhoto"
        loading="lazy"
      />
    </div>

    <div class="col-8">
      <p class="title mb-1">
        <span class="plot mr-1">
          {{ sale.projectUnit.plot }}
        </span>
        {{ sale.projectUnit.street }}
      </p>
      <p class="font-bold context">{{ sale.project.name }}</p>
      <p class="context">{{ sale.projectUnitType.name }}</p>
      <p class="context">
        {{ sale.projectUnitFloorPlan.name }}
      </p>
    </div>
  </div>
</template>

<script>
import { setAltPhoto } from "@/utils/image";

export default {
  components: {},
  mixins: [],
  props: {
    sale: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      setAltPhoto
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-unit-summary {
  img.main-photo {
    @include image(cover);
    width: 150px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px #00000030;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    align-items: flex-end;

    .plot {
      font-size: 16px;
      border: solid 1px $color-accent2;
      color: $color-accent2;
      border-radius: 4px;
      padding: 2px 10px;
      text-align: center;
    }
  }
  .context {
    color: #656565;
  }
}
</style>
